export const selectedCity = (city) => {
  return {
    type: "SELECT_CITY",
    data: city,
  };
};

export const getCartInfo = (info) => {
  return {
    type: "CART_INFO",
    data: info,
  };
};

export const changeCartPopup = (condition) => {
  return {
    type: "CART_POPUP",
    data: condition,
  };
};

export const getUserProfileInfo = (info) => {
  return {
    type: "GET_PROFILE",
    data: info,
  };
};
