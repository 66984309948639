import React from "react";
import { BounceLoader } from "react-spinners";

function LoadingScreen() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 4,
      }}
    >
      <div
        style={{
          width: "18rem",
          height: "18rem",
          backgroundColor: "white",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BounceLoader color="rgba(233, 79, 24, 1)" size={52} loading={true} />
      </div>
    </div>
  );
}

export default LoadingScreen;
