import { combineReducers } from "redux";
import query from "./query";
import auth from "./auth";
import appdata from "./appdata";
import cart from "./cart";
import cityid from "./cityid";
import refer from "./refer";

const rootReducer = combineReducers({
  query,
  auth,
  appdata,
  cart,
  cityid,
  refer,
});

export default rootReducer;
