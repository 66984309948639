import React from "react";
import classes from "./CreateCampain.module.css";
import TryBeforeBuyWrapper from "../../../layout/TryBeforeBuyWrapper";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

const CreateCampainForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    swal({
      icon: "success",
      text: `Request Submitted Sucessfully`,
    }); // You can replace this with your own logic
    reset();
  };
  return (
    <div className={classes.formWrapper}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.inputWrap}>
          <div className={classes.formInput}>
            <p className={classes.label}>Campaign Name</p>
            <input
              type="text"
              className={classes.input}
              placeholder="Enter campaign name"
              {...register("campaignName", { required: true })}
            />
            {errors.campaignName && (
              <span className={classes.error}>Campaign name is required</span>
            )}
          </div>
          <div className={classes.formInput}>
            <p className={classes.label}>Product</p>
            <input
              type="text"
              className={classes.input}
              placeholder="Enter product name"
              {...register("product", { required: true })}
            />
            {errors.product && (
              <span className={classes.error}>Product name is required</span>
            )}
          </div>
        </div>

        <div className={classes.inputWrapRent}>
          <div className="">
            <label htmlFor="trialWindow">Trial Window</label>
            <select
              className={classes.input}
              id="trialWindow"
              {...register("trialWindow", { required: true })}
            >
              <option value="">Select trial window</option>
              <option value="3">3</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
            </select>
            {errors.trialWindow && (
              <span className={classes.error}>Trial window is required</span>
            )}
          </div>
          <div className="">
            <label htmlFor="city">City</label>
            <select
              className={classes.input}
              id="city"
              {...register("city", { required: true })}
            >
              <option value="">Select city</option>
              <option value="Mumbai">Mumbai</option>
              <option value="Pune">Pune</option>
              <option value="Delhi">Delhi</option>
              <option value="Bangalore">Bangalore</option>
              <option value="Hyderabad">Hyderabad</option>
              <option value="Chennai">Chennai</option>
              <option value="Gurgaon">Gurgaon</option>
              <option value="Ahmedabad">Ahmedabad</option>
              <option value="Kolkata">Kolkata</option>
            </select>
            {errors.city && (
              <span className={classes.error}>City is required</span>
            )}
          </div>
        </div>

        <div className={classes.inputWrapRent}>
          <div className="">
            <p className={classes.label}>Rent Per Unit (in Rs.)</p>
            <input
              type="number"
              className={classes.input}
              placeholder="Enter rent per unit"
              {...register("rentPerUnit", { required: true })}
            />
            {errors.rentPerUnit && (
              <span className={classes.error}>Rent per unit is required</span>
            )}
          </div>
          <div className="">
            <p className={classes.label}>Discount Cashback (in Rs.)</p>
            <input
              type="number"
              className={classes.input}
              placeholder="Enter discount cashback"
              {...register("discountCashback", { required: true })}
            />
            {errors.discountCashback && (
              <span className={classes.error}>
                Discount cashback is required
              </span>
            )}
          </div>
        </div>

        <div className={classes.inputWrap}>
          <div className={`${classes.formInput} ${classes.session}`}>
            <p className={classes.label}>Onboarding Sessions With Prospect</p>
            <div className={classes.radioInput}>
              <div>
                <input
                  type="radio"
                  id="option1"
                  name="options"
                  value="yes"
                  className={classes.radioInput}
                  {...register("onboardingSessions", { required: true })}
                />
                <label htmlFor="option1" className={classes.radioLabel}>
                  Yes
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="option2"
                  name="options"
                  value="no"
                  className={classes.radioInput}
                  {...register("onboardingSessions", { required: true })}
                />
                <label htmlFor="option2" className={classes.radioLabel}>
                  No
                </label>
              </div>
            </div>
            {errors.onboardingSessions && (
              <span className={classes.error}>
                Onboarding sessions selection is required
              </span>
            )}
          </div>

          <div className={`${classes.formInput} ${classes.product}`}>
            <p className={classes.label}>Product Manual Link</p>
            <input
              type="text"
              className={classes.input}
              placeholder="Product manual link"
              {...register("productManualLink", { required: true })}
            />
            {errors.productManualLink && (
              <span className={classes.error}>
                Product manual link is required
              </span>
            )}
          </div>
        </div>

        <div className={classes.inputWrap}>
          <div className={classes.formInput}>
            <p className={classes.label}>Product Video Link</p>
            <input
              type="text"
              className={classes.input}
              placeholder="Product video link"
              {...register("productVideoLink", { required: true })}
            />
            {errors.productVideoLink && (
              <span className={classes.error}>
                Product video link is required
              </span>
            )}
          </div>

          <div className={classes.formInput}>
            <p className={classes.label}>Packaging Instructions</p>
            <input
              type="text"
              className={classes.input}
              placeholder="Enter packaging instructions"
              {...register("packagingInstructions", { required: true })}
            />
            {errors.packagingInstructions && (
              <span className={classes.error}>
                Packaging instructions are required
              </span>
            )}
          </div>
        </div>

        <button type="submit" className={classes.submitBtn}>
          Submit
        </button>
      </form>
    </div>
  );
};

const CreateCampain = () => {
  return (
    <TryBeforeBuyWrapper>
      <h1 className={classes.title}>Create New Campaign</h1>
      <div className={classes.wrapper}>
        <CreateCampainForm />
      </div>
    </TryBeforeBuyWrapper>
  );
};

export default CreateCampain;
