import React from "react";
import classes from "./Navbar.module.css";
// import HeaderIcon from "../assets/img/Contact/HeaderIcon.svg";
import BrandIcon from "../../../assets/img/Contact/HeaderIcon.svg";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className={classes.wrapper}>
      <Link to="/try-before-buy/campaign">
        <img src={BrandIcon} className={classes.brand} />
      </Link>
      <div className={classes.rightSide}>
        <Link to="/try-before-buy/campaign/create">
          <p> New Campaign</p>
        </Link>
        <Link to="/try-before-buy/campaign/analytics">
          <p>Analytics</p>
        </Link>
        <Link to="/try-before-buy/inventory">
          <p>Track Inventory</p>
        </Link>
        <Link to="/try-before-buy/tyby-calculator">
          <p>TYBY Calculator</p>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
