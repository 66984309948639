const initialState = {
  city: "",
  info: "",
  cartPopup: false,
  profile: "",
};

const cart = (state = initialState, action) => {
  switch (action.type) {
    case "SELECT_CITY":
      state.city = action.data;
      return {
        ...state,
      };
    case "CART_INFO":
      state.info = action.data;
      return {
        ...state,
      };
    case "CART_POPUP":
      state.cartPopup = action.data;
      return {
        ...state,
      };
    case "GET_PROFILE":
      state.profile = action.data;
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default cart;
