import React, { useState } from "react";
import styles from "../../../assets/style/TryBeforeBuy/inventory/ConsignmentPopup.module.css";
import AddIcon from "@mui/icons-material/Add";
import Modal from "../../Basic/Modal";
import closebtn from "../../../assets/svg/closeBtn.svg";
import ConsignmentForm from "./ConsignmentForm";
const ConsignmentPopup = () => {
  const [open, setopen] = useState(false);
  return (
    <div>
      <div className={styles.buttonContainer}>
        <button
          className={styles.uploadConsignmentButton}
          onClick={() => setopen(true)}
        >
          <AddIcon fontSize="large" />
          Upload New Consignment
        </button>
        <Modal open={open}>
          <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
              <h1>Add New Consignment</h1>
              <img
                src={closebtn}
                onClick={() => setopen(false)}
                alt="close button"
              />
            </div>
            <div className={styles.modalBody}>
              <ConsignmentForm />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ConsignmentPopup;
