const initialState = {};

const appdata = (state = initialState, action) => {
  switch (action.type) {
    case "GET_APPDATA":
      state = action.data;
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default appdata;
