import React from "react";
import ActiveInventory from "../../../components/TryBeforeBuy/Inventory/ActiveInventory";
import Consignment from "../../../components/TryBeforeBuy/Inventory/Consignment";
import TryBeforeBuyWrapper from "../../../layout/TryBeforeBuyWrapper";
import classes from "./inventory.module.css";
const Inventory = () => {
  return (
    <TryBeforeBuyWrapper>
      <div className={classes.container}>
        <ActiveInventory />
        <Consignment />
      </div>
    </TryBeforeBuyWrapper>
  );
};

export default Inventory;
