const initialState = {};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case "AUTH_CHANGE":
      state = action.data;
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default auth;
