let initialState = {
  isOpen: false,
  coupon: "",
};

const refer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COUPON":
      state.coupon = action.data;
      return {
        ...state,
      };
    case "TOGGLE_COUPON":
      state.isOpen = !state.isOpen;
      return {
        ...state,
      };
    case "REMOVE_REFER_COUPON":
      state.coupon = "";
      return state;
    default:
      return state;
  }
};

export default refer;
