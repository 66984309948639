import React from "react";
import { Link } from "react-router-dom";

//css
import classes from "../assets/style/Footer.module.css";

//all images & svg
import appStore from "../assets/img/PlayStore.png";
import fb from "../assets/svg/fb.svg";
import googlePlay from "../assets/img/AppStore.png";
import insta from "../assets/svg/insta.svg";
import BrandLogo from "../assets/svg/logo.svg";
import headingClasses from "../assets/style/common/fonts.module.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { isMobile } from "react-device-detect";
function Footer() {
  return (
    <div className={classes.container} id="footer">
      <div className={classes.upperContainer}>
        <div>
          <div className={classes.logoContainer}>
            <Link to="/">
              <img
                src={BrandLogo}
                loading="lazy"
                alt="SmartOwn Logo"
                title="SmartOwn Logo"
                className={classes.footerLogo}
              />
            </Link>
            <p
              className={headingClasses.paragraph2_regular}
              style={{ marginTop: "1rem" }}
            >
              A-224, Synthofine Industrial Estate
            </p>
            <p className={headingClasses.paragraph2_regular}>
              Goregaon East Mumbai 400063, India.
            </p>
          </div>

          <div className={classes.socialIcon}>
            <a
              href="https://www.facebook.com/SmartOwnIndia"
              rel="nofollow"
              target="_new"
            >
              {" "}
              <img
                loading="lazy"
                src={fb}
                alt="Facebook Icon"
                title="Facebook Icon"
              />
            </a>
            {/* <div>
              <img  src={linkedin} alt="logo" />
            </div> */}
            <a
              href="https://www.instagram.com/smart.own/"
              rel="nofollow"
              target="_insta"
            >
              <img
                loading="lazy"
                src={insta}
                alt="Instagram Icon"
                title="Instagram Icon"
              />
            </a>
            {/* <div>
              {" "}
              <img  src={twitter} alt="logo" />
            </div> */}
          </div>
        </div>
        <div>
          <ul className={classes.footerLink}>
            <Link
              to="/about"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <li>About us</li>
            </Link>
            <Link
              to="/products"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <li>Products</li>
            </Link>
            <Link
              to="/partner-with-us"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <li>Partner with us</li>
            </Link>
            <Link
              to="/try-before-buy"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <li>Try Before Buy</li> ̰
            </Link>
          </ul>
        </div>
        <div>
          <ul className={classes.footerLink}>
            {/* <li>Help center</li> */}
            <Link
              to="/contact"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <li>Contact us</li>
            </Link>
            <Link
              to="/privacypolicy
              "
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <li>Privacy Policy</li>
            </Link>
            <Link
              to="/termconditions"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <li>Terms & Conditions</li>
            </Link>
            <Link
              to="/blogs"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <li>Blogs</li>
            </Link>
          </ul>
        </div>
        {/* mobile responsive footer */}
        <div className={classes.mobileFooter}>
          <div>
            <ul className={classes.mobileFooterLink}>
              <Link
                to="/about"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <li>About us</li>
              </Link>
              <Link
                to="/products"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <li>Products</li>
              </Link>
              <Link
                to="/blogs"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <li>Blogs</li>
              </Link>
              <Link
                to="/partner-with-us"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <li>Partner with us</li>
              </Link>
            </ul>
          </div>
          <div>
            <ul className={classes.mobileFooterLink}>
              <Link
                to="/contact"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <li>Contact us</li>
              </Link>
              <Link
                to="/privacyPolicy"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <li>Privacy Policy</li>
              </Link>
              <Link
                to="/termConditions"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <li>Terms & Conditions</li>
              </Link>
            </ul>
          </div>
          <div className={classes.storeMobile}>
            <p className={headingClasses.paragraph2_medium}>Download App</p>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.smartown"
                target="_appstore"
                rel="nofollow"
              >
                {" "}
                <img loading="lazy" src={appStore} alt="logo" />{" "}
              </a>
            </div>
            <div>
              <a
                href="https://apps.apple.com/in/app/smartown/id1525507449"
                target="_iosstore"
                rel="nofollow"
              >
                {" "}
                <img loading="lazy" src={googlePlay} alt="logo" />
              </a>
            </div>
          </div>
        </div>
        <div className={classes.social}>
          <div className={classes.helpContainer}>
            <p className={headingClasses.paragraph2_medium}>Need Help?</p>
            <div className={classes.phoneno}>
              <LocalPhoneIcon sx={{ fontSize: 20 }} />
              <a
                href="tel:+91 2250646499"
                className={headingClasses.paragraph1_medium}
                style={{ textDecoration: "underline" }}
              >
                +91 2250646499
              </a>

              <span className={headingClasses.paragraph1_regular}>
                (10AM to 07PM)
              </span>
            </div>
          </div>

          {isMobile ? (
            ""
          ) : (
            <p
              className={headingClasses.paragraph2_medium}
              style={{ marginTop: "2rem" }}
            >
              Download App
            </p>
          )}
          <div className={classes.store}>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.smartown"
                target="_appstore"
                rel="nofollow"
              >
                {" "}
                <img loading="lazy" src={appStore} alt="logo" />{" "}
              </a>
            </div>
            <div>
              <a
                href="https://apps.apple.com/in/app/smartown/id1525507449"
                target="_iosstore"
                rel="nofollow"
              >
                {" "}
                <img loading="lazy" src={googlePlay} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
