import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "../../../assets/style/TryBeforeBuy/inventory/ConsignmentForm.module.css";
import TybyCalculations from "./TybyCalculations";

const TybyCalculator = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  const [formData, setFormData] = useState(null);

  const retailSalePrice = watch([
    "retailSalePrice",
    "numRentalsPerPiece",
    "conversionsPercentage",
  ]);

  const rentalIncome = watch("rentPaidByCustomer");
  const terminalValue = watch("terminalValue");
  const feees = watch("successFees");
  const watchCashback = watch(["cashbackPercentage", "rentPaidByCustomer"]);

  const salesCalculations =
    parseInt(retailSalePrice[0]) *
    parseInt(retailSalePrice[1]) *
    parseInt(retailSalePrice[2]);

  const rentalIcomeCalculation =
    parseInt(rentalIncome) * parseInt(retailSalePrice[1]);

  const successFees =
    parseInt(retailSalePrice[1]) *
    parseInt(retailSalePrice[2]) *
    parseInt(feees);

  const customerCashback =
    parseInt(retailSalePrice[1]) *
    parseInt(retailSalePrice[2]) *
    parseInt(watchCashback[0]) *
    parseInt(watchCashback[1]);

  return (
    <div
      className={styles.tyByCalculator}
      style={{ display: "flex", gap: "4rem" }}
    >
      <div className={styles.formWrapper}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputWrap}>
            <div className={styles.formInput}>
              <p className={styles.label}>Retail Sale Price</p>
              <input
                type="number"
                className={styles.input}
                name="retailSale"
                // onChange={(e) => handleChangeHandler(e)}
                placeholder="Enter retail sale price"
                {...register("retailSalePrice", { required: true })}
              />
              {errors.retailSalePrice && (
                <span className={styles.error}>
                  Retail sale price is required
                </span>
              )}
            </div>
          </div>

          <div className={styles.inputWrap}>
            <div className={styles.formInput}>
              <p className={styles.label}>Number of Rentals Per Piece</p>
              <input
                type="number"
                className={styles.input}
                placeholder="Enter number of rentals per piece"
                {...register("numRentalsPerPiece", { required: true })}
              />
              {errors.numRentalsPerPiece && (
                <span className={styles.error}>
                  Number of rentals per piece is required
                </span>
              )}
            </div>
            <div className={styles.formInput}>
              <p className={styles.label}>Average No.of Days for Rental</p>
              <input
                type="text"
                className={styles.input}
                placeholder="Enter average number of days for rental"
                {...register("avgNumDaysForRental", { required: true })}
              />
              {errors.avgNumDaysForRental && (
                <span className={styles.error}>
                  Average number of days for rental is required
                </span>
              )}
            </div>
          </div>

          <div className={styles.inputWrap}>
            <div className={styles.formInput}>
              <p className={styles.label}>Downtime Days</p>
              <input
                type="text"
                className={styles.input}
                placeholder="Enter downtime days"
                {...register("downtimeDays", { required: true })}
              />
              {errors.downtimeDays && (
                <span className={styles.error}>Downtime days is required</span>
              )}
            </div>
          </div>

          <div className={styles.inputWrap}>
            <div className={styles.formInput}>
              <p className={styles.label}>Terminal Value</p>
              <input
                type="text"
                className={styles.input}
                placeholder="Enter terminal value of number of days in use"
                {...register("terminalValue", { required: true })}
              />
              {errors.terminalValue && (
                <span className={styles.error}>Terminal value is required</span>
              )}
            </div>
            <div className={styles.formInput}>
              <p className={styles.label}>Conversions Percentage</p>
              <input
                type="number"
                className={styles.input}
                placeholder="Enter conversions in percentage"
                {...register("conversionsPercentage", { required: true })}
              />
              {errors.conversionsPercentage && (
                <span className={styles.error}>Conversions Percentage</span>
              )}
            </div>
          </div>

          <div className={styles.inputWrap}>
            <div className={styles.formInput}>
              <p className={styles.label}>Rent Paid by Customer</p>
              <input
                type="text"
                className={styles.input}
                placeholder="Enter rent paid by customer"
                {...register("rentPaidByCustomer", { required: true })}
              />
              {errors.rentPaidByCustomer && (
                <span className={styles.error}>
                  Rent paid by customer is required
                </span>
              )}
            </div>
          </div>

          <div className={styles.inputWrap}>
            <div className={styles.formInput}>
              <p className={styles.label}>Success Fees</p>
              <input
                type="text"
                className={styles.input}
                placeholder="Enter success fees"
                {...register("successFees", { required: true })}
              />
              {errors.successFees && (
                <span className={styles.error}>Success fees is required</span>
              )}
            </div>
            <div className={styles.formInput}>
              <p className={styles.label}>Cash-Back Percentage</p>
              <input
                type="text"
                className={styles.input}
                placeholder="Enter cashback percentage"
                {...register("cashbackPercentage", { required: true })}
              />
              {errors.cashbackPercentage && (
                <span className={styles.error}>
                  Cashback percentage is required
                </span>
              )}
            </div>
          </div>

          <button type="submit" className={styles.submitBtn}>
            Submit
          </button>
        </form>
      </div>

      <div className={styles.calculationWrapper}>
        <TybyCalculations
          sales={salesCalculations}
          rentalIncome={rentalIcomeCalculation}
          terminalValue={terminalValue}
          rentalsPerPiece={retailSalePrice[1]}
          successFees={successFees}
          customerCashback={customerCashback}
        />
      </div>
    </div>
  );
};

export default TybyCalculator;
