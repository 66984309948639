import React from "react";
import TybyCalculatorForm from "../../../components/TryBeforeBuy/TybyCalculator/TybyCalculatorForm";
import TryBeforeBuyWrapper from "../../../layout/TryBeforeBuyWrapper";
import styles from "./TybyCalculator.module.css";
import headingClasses from "../../../assets/style/common/fonts.module.css";
import TybyCalculations from "../../../components/TryBeforeBuy/TybyCalculator/TybyCalculations";
const TybyCalculator = () => {
  const formValues = (values) => {};
  return (
    <TryBeforeBuyWrapper>
      <div className={styles.container}>
        <h2 className={headingClasses.heading1}>
          Try Before You Buy calculator
        </h2>
        <TybyCalculatorForm />
      </div>
    </TryBeforeBuyWrapper>
  );
};

export default TybyCalculator;
