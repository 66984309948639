export const increCounter = (count) => {
  return {
    type: "INCRE_COUNT",
    data: count,
  };
};

export const selectedItem = (query) => {
  return {
    type: "SELECTED_ITEM",
    data: query,
  };
};

export const searchProduct = (search) => {
  return {
    type: "SEARCH_PRODUCT",
    data: search,
  };
};

export const selectedCategory = (category) => {
  return {
    type: "SELECT_CATEGORY",
    data: category,
  };
};

export const selectCategoryName = (name) => {
  return {
    type: "SELECT_CATEGORY_NAME",
    data: name,
  };
};

export const addFavItems = (items) => {
  return {
    type: "ADD_FAV",
    data: items,
  };
};
export const getAppData = (data) => {
  return {
    type: "GET_APPDATA",
    data: data,
  };
};

export const loginHandler = (profile) => {
  return {
    type: "AUTH_CHANGE",
    data: profile,
  };
};

export const getReferCode = (code) => {
  return {
    type: "REFER_CODE",
    data: code,
  };
};

export const removeReferalCode = () => {
  return {
    type: "REMOVE_REFER_CODE",
  };
};
