import React from "react";
import ConsignmentTable from "./ConsignmentTable";
import headingClasses from "../../../assets/style/common/fonts.module.css";
import ConsignmentPopup from "./ConsignmentPopup";
const Consignment = () => {
  return (
    <div style={{ marginTop: "7rem" }}>
      <h3 className={headingClasses.heading3}>Consignments</h3>
      <ConsignmentTable />
      <ConsignmentPopup />
    </div>
  );
};

export default Consignment;
