import React from "react";
import classes from "../../assets/style/Modal.module.css";

function Modal({ open, children }) {
  if (!open) return null;
  return (
    <div className={classes.overlayStyle}>
      <div className={classes.modal}>
        {/* <button onClick={onClose}>Close Modal</button> */}
        {children}
      </div>
    </div>
  );
}

export default Modal;
