import React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import styles from "../../../assets/style/TryBeforeBuy/inventory/ConsignmentForm.module.css";

const ConsignmentForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    swal({
      icon: "success",
      text: `Consignment Created Successfully`,
    }); // You can replace this with your own logic
    reset();
  };

  return (
    <div>
      <div className={styles.formWrapper}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputWrap}>
            <div className={styles.formInput}>
              <p className={styles.label}>Consignment No.</p>
              <input
                type="text"
                className={styles.input}
                placeholder="Enter consignment number"
                {...register("consignmentNo", { required: true })}
              />
              {errors.consignmentNo && (
                <span className={styles.error}>
                  Consignment number is required
                </span>
              )}
            </div>
            <div className={styles.formInput}>
              <p className={styles.label}>Received On</p>
              <input
                type="date"
                className={styles.input}
                placeholder="Enter received date"
                {...register("receivedOn", { required: true })}
              />
              {errors.receivedOn && (
                <span className={styles.error}>Received date is required</span>
              )}
            </div>
          </div>

          <div className={styles.inputWrap}>
            <div className={styles.formInput}>
              <p className={styles.label}>Device Count</p>
              <input
                type="number"
                className={styles.input}
                placeholder="Enter device count"
                {...register("deviceCount", { required: true })}
              />
              {errors.deviceCount && (
                <span className={styles.error}>Device count is required</span>
              )}
            </div>
            <div className={styles.formInput}>
              <p className={styles.label}>Courier Info</p>
              <input
                type="text"
                className={styles.input}
                placeholder="Enter courier information"
                {...register("courierInfo", { required: true })}
              />
              {errors.courierInfo && (
                <span className={styles.error}>
                  Courier information is required
                </span>
              )}
            </div>
          </div>

          <div className={styles.formInput}>
            <p className={styles.label}>Received By</p>
            <input
              type="text"
              className={styles.input}
              placeholder="Enter receiver's name"
              {...register("receivedBy", { required: true })}
            />
            {errors.receivedBy && (
              <span className={styles.error}>Receiver's name is required</span>
            )}
          </div>

          <button type="submit" className={styles.submitBtn}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ConsignmentForm;
