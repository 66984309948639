const cityid = (state = null, action) => {
  switch (action.type) {
    case "SELECT_CITY_ID":
      // state.city = action.data;
      return action.data;

    default:
      return state;
  }
};

export default cityid;
