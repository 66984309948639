import React from "react";
import SuspenseLoader from "./layout/SuspenseLoader";

const Layout = ({ children }) => {
  return (
    <React.Suspense fallback={<SuspenseLoader />}>{children}</React.Suspense>
  );
};

export default Layout;
