import React, { memo } from "react";
import classes from "../../../pages/TryBeforeBuy/Dashboard/Dashboard.module.css";

const TybyCalculations = ({
  sales,
  rentalIncome,
  terminalValue,
  rentalsPerPiece,
  successFees,
  customerCashback,
}) => {
  function convertToIndianNumber(number) {
    var str = String(number);
    var result = "";

    for (var i = str.length - 1, j = 0; i >= 0; i--, j++) {
      if (j > 0 && j % 3 === 0) {
        result = "," + result;
      }
      result = str[i] + result;
    }

    return result;
  }
  const initialTrialCost = 1100;
  const initialRepair = 500;
  const totalIncome = sales + rentalIncome + parseInt(terminalValue);
  const totalExpenses =
    rentalsPerPiece * initialTrialCost +
    successFees +
    rentalsPerPiece * initialRepair +
    customerCashback;

  const netIncome =
    totalIncome && totalExpenses ? totalExpenses - totalIncome : 0;

  return (
    <table className={classes.table}>
      <tbody>
        <tr>
          <th>Income</th>
        </tr>
        <tr>
          <th>Sales</th>
          <td>{sales ? convertToIndianNumber(sales) : 0}</td>
        </tr>
        <tr>
          <th>Rental Income</th>
          <td>{rentalIncome ? convertToIndianNumber(rentalIncome) : 0}</td>
        </tr>
        <tr>
          <th>Terminal Value</th>
          <td>{terminalValue ? convertToIndianNumber(terminalValue) : 0}</td>
        </tr>
        <tr className={classes.total}>
          <th>Total Income</th>
          <td>{totalIncome ? convertToIndianNumber(totalIncome) : 0}</td>
        </tr>
        <tr>
          <th>Expenses</th>
        </tr>
        <tr>
          <th>Trial Costs</th>
          <td>
            {rentalsPerPiece
              ? convertToIndianNumber(rentalsPerPiece * initialTrialCost)
              : 0}
          </td>
        </tr>
        <tr>
          <th>Success Fees</th>
          <td>{successFees ? convertToIndianNumber(successFees) : 0}</td>
        </tr>
        <tr>
          <th>Repair and Others</th>
          <td>
            {rentalsPerPiece
              ? convertToIndianNumber(rentalsPerPiece * initialRepair)
              : 0}
          </td>
        </tr>
        <tr>
          <th>Customer Cashback </th>
          <td>
            {customerCashback ? convertToIndianNumber(customerCashback) : 0}
          </td>
        </tr>
        <tr className={classes.total}>
          <th>Total Expenses</th>
          <td>{totalExpenses ? convertToIndianNumber(totalExpenses) : 0}</td>
        </tr>
        <tr className={classes.total}>
          <th>Net Income</th>
          <td>{convertToIndianNumber(netIncome)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default memo(TybyCalculations);
