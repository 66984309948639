import React from "react";
import styles from "../../../assets/style/TryBeforeBuy/analytics/Table.module.css";

const ActiveInventoryTable = () => {
  const data = [
    {
      label: "Total Devices",
      value: 315,
    },
    {
      label: "Currently On Trial",
      value: 250,
    },
    {
      label: "Waiting for Repair",
      value: 10,
    },
    {
      label: "Available for Trial",
      value: 50,
    },
    {
      label: "In Transit",
      value: 5,
    },
  ];

  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        {data.map((item, index) => (
          <>
            <tbody key={index}>
              <tr>
                <th>{item.label}</th>
                <td>{item.value}</td>
              </tr>
            </tbody>
          </>
        ))}
      </table>
    </div>
  );
};

export default ActiveInventoryTable;
