import React from "react";
import classes from "../../../assets/style/TryBeforeBuy/inventory/ActiveInventory.module.css";
import headingClasses from "../../../assets/style/common/fonts.module.css";
import ActiveInventoryTable from "./ActiveInventoryTable";
const ActiveInventory = () => {
  return (
    <div>
      <h3 className={headingClasses.heading3}>Active Inventory</h3>
      <ActiveInventoryTable />
    </div>
  );
};

export default ActiveInventory;
