import * as React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

//nested components end

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAppData, loginHandler } from "./actions";
import { API } from "./backend.js/backend";

import { getCartInfo } from "./actions/cart";
import { RemoveTrailingSlash } from "./utils/RemoveTrailingSlash";
import { useState } from "react";
import SuspenseLoader from "./layout/SuspenseLoader";
import Layout from "./Layout";
import CreateCampain from "./pages/TryBeforeBuy/CreateCampain/CreateCampain";
import Inventory from "./pages/TryBeforeBuy/inventory/Inventory";
import TybyCalculator from "./pages/TryBeforeBuy/TybyCalculator/TybyCalculator";

const TryBeforeBuy = React.lazy(() => import("./pages/TryBeforeBuy"));
const BlogListing = React.lazy(() => import("./pages/BlogListing"));
const BlogView = React.lazy(() => import("./components/Blogs/BlogView"));
const PendingInvoice = React.lazy(() => import("./pages/PendingInvoice"));
const JotForm = React.lazy(() => import("./pages/JotForm"));
const Protected = React.lazy(() => import("./Protected"));
const PostPurchaseSurvey = React.lazy(() =>
  import("./components/OrderFlow/PostPurchaseSurvey")
);

const About = React.lazy(() => import("./pages/About"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Home = React.lazy(() => import("./pages/Home"));
const TermsAndCondition = React.lazy(() => import("./pages/TermsAndCondition"));
const Partner = React.lazy(() => import("./pages/Partner"));
const PerchaseProduct = React.lazy(() =>
  import("./components/Product/PerchaseProduct")
);
const PaymentInvoiceSummary = React.lazy(() =>
  import("./components/UserProfile/PaymentInvoiceSummary")
);
const Category = React.lazy(() => import("./pages/Category"));
const Product = React.lazy(() => import("./pages/Product"));
const ProductListing = React.lazy(() => import("./pages/ProductListing"));
const SearchExperience = React.lazy(() => import("./pages/SearchExperience"));
const HowItWorks = React.lazy(() => import("./pages/HowitWorks"));
const UserProfile = React.lazy(() => import("./pages/UserProfile"));

//nested components
const InvoiceAndPayments = React.lazy(() =>
  import("./components/UserProfile/InvoiceAndPayments")
);
const Myrentals = React.lazy(() =>
  import("./components/UserProfile/Myrentals")
);
const OrderDetails = React.lazy(() =>
  import("./components/UserProfile/OrderDetails")
);
const Orders = React.lazy(() => import("./components/UserProfile/Orders"));
const ReferAndEarn = React.lazy(() =>
  import("./components/UserProfile/ReferAndEarn")
);
const UserProfileRightbar = React.lazy(() =>
  import("./components/UserProfile/UserProfileRightBar")
);
const Wishlist = React.lazy(() => import("./components/UserProfile/Wishlist"));
const Hamburger = React.lazy(() => import("./components/Basic/Hamburger"));
const GoogleFbLogin = React.lazy(() =>
  import("./components/Login/GoogleFbLogin")
);
const AddDeliveryAddress = React.lazy(() =>
  import("./components/OrderFlow/AddDeliveryAddress")
);
const CardPayment = React.lazy(() =>
  import("./components/OrderFlow/CardPayment")
);
const OnlinePay = React.lazy(() => import("./components/OrderFlow/OnlinePay"));
const OrderConfirmed = React.lazy(() =>
  import("./components/OrderFlow/OrderConfirmed")
);
const PaymentMethods = React.lazy(() =>
  import("./components/OrderFlow/PaymentMethods")
);
const SelectDeliveryAdress = React.lazy(() =>
  import("./components/OrderFlow/SelectDeliveryAddress")
);
const UPI = React.lazy(() => import("./components/OrderFlow/UPI"));
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));
const Privacy = React.lazy(() => import("./pages/Privacy"));
const SubCategory = React.lazy(() => import("./pages/SubCategory"));
const Thankyou = React.lazy(() => import("./pages/Thankyou"));
const Analytics = React.lazy(() =>
  import("./components/TryBeforeBuy/Analytics/Analytics")
);
const Dashboard = React.lazy(() =>
  import("./pages/TryBeforeBuy/Dashboard/Dashboard")
);

function App() {
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);
  const [isAppready, setIsAppready] = useState(false);
  const dispatch = useDispatch();
  const [bannerImges, setBannerImges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [favItems, setFavItems] = useState([]);
  const [cat, setCat] = useState([]);

  const getFavItems = () => {
    setLoading(true);

    axios
      .get(
        `${API}/dashboard/${
          cart.city?.id ? cart.city.id : "03da3aba-389b-4af9-abe4-604c13bab136"
        }`
      )
      .then((response) => {
        setFavItems(response.data.favourites);
        setCat(response.data.segments);
        // dispatch(addFavItems(response.data.favourites));
        setBannerImges(response.data.banners);
        setLoading(false);
        // console.log("Fav Items Fetched");
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  const fetchAppData = async () => {
    await axios.get(`${API}/appData`).then((response) => {
      setIsAppready(true);
      dispatch(getAppData(response.data));
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  React.useEffect(() => {
    scrollToTop();
    getFavItems();
    fetchAppData();
    // console.log("Fetching Dashboard");
  }, [cart.city?.id]);

  const getUserProfile = () => {
    axios
      .get(`${API}/profile`, {
        headers: { Authorization: auth.access_token },
      })
      .then((response) => {
        let resData = {
          access_token: auth.access_token,
          expiration: auth.expiration,
          profile: {
            ...response.data,
          },
        };
        dispatch(loginHandler(resData));
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const fetchUserCartInfo = () => {
    if (auth.access_token) {
      axios
        .post(
          `${API}/cart`,
          { city_id: cart.city.id },
          { headers: { Authorization: auth.access_token } }
        )
        .then((response) => {
          // console.log("cart info fetch");
          dispatch(getCartInfo(response.data));
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  React.useEffect(() => {
    if (auth.access_token) {
      getUserProfile();
      fetchUserCartInfo();
    }
  }, []);

  return (
    <>
      {!isAppready && <SuspenseLoader />}
      <BrowserRouter>
        {/* remove trailing slash issue */}
        <RemoveTrailingSlash />
        {/* remove trailing slash issue */}
        <Routes>
          {/* main pages */}

          <Route
            path="/kyc"
            element={
              <Layout>
                <JotForm />
              </Layout>
            }
          />
          <Route
            path="/"
            element={
              <Layout>
                <Home
                  bannerImges={bannerImges}
                  loading={loading}
                  cat={cat}
                  favItems={favItems}
                />
              </Layout>
            }
          />
          <Route
            path="/Login"
            element={
              <Layout>
                <GoogleFbLogin />
              </Layout>
            }
          />
          <Route
            path="/contact"
            element={
              <Layout>
                <Contact />
              </Layout>
            }
          />

          {/* TRY BEFORE BUY  */}
          <Route
            path="/try-before-buy"
            element={
              <Layout>
                <TryBeforeBuy />
              </Layout>
            }
          />
          <Route
            path="/try-before-buy/campaign/analytics"
            element={
              <Layout>
                <Analytics />
              </Layout>
            }
          />
          <Route
            path="/try-before-buy/inventory"
            element={
              <Layout>
                <Inventory />
              </Layout>
            }
          />
          <Route
            path="/try-before-buy/tyby-calculator"
            element={
              <Layout>
                <TybyCalculator />
              </Layout>
            }
          />
          <Route
            path="/try-before-buy/campaign"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route
            path="/try-before-buy/campaign/create"
            element={<CreateCampain />}
          />
          {/* TRY BEFORE BUY END */}

          <Route
            path="/about"
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
          <Route
            path="/how-it-works"
            element={
              <Layout>
                <HowItWorks />
              </Layout>
            }
          />
          <Route
            path="/products"
            element={
              <Layout>
                <Category />
              </Layout>
            }
          />
          <Route
            path="/termconditions"
            element={
              <Layout>
                <TermsAndCondition />
              </Layout>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <Layout>
                <Privacy />
              </Layout>
            }
          />
          <Route
            path="/products/:category/:sku"
            element={
              <Layout>
                <Product />
              </Layout>
            }
          />
          <Route
            path="/products/:category"
            element={
              <Layout>
                <ProductListing />
              </Layout>
            }
          />

          <Route
            path="/blogs"
            element={
              <Layout>
                <BlogListing />
              </Layout>
            }
          />
          <Route
            path="/blogs/:blogTitle"
            element={
              <Layout>
                <BlogView />
              </Layout>
            }
          />
          <Route
            path="/partner-with-us"
            element={
              <Layout>
                <Partner />
              </Layout>
            }
          />

          {/* for subcategories */}
          <Route
            path="/product/:category/:subCategory"
            element={
              <Layout>
                <SubCategory />
              </Layout>
            }
          />
          <Route
            path="/search/:searchquery"
            element={
              <Layout>
                <SearchExperience />
              </Layout>
            }
          />

          <Route
            path="/purchase"
            element={
              auth.access_token ? (
                <Layout>
                  <PerchaseProduct />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/post-purchase-survey"
            element={
              auth.access_token ? (
                <Layout>
                  <PostPurchaseSurvey />
                </Layout>
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/thankyou"
            element={
              <Layout>
                <Thankyou />
              </Layout>
            }
          />
          <Route
            path="/thank-you-partner"
            element={
              <Layout>
                <Thankyou />
              </Layout>
            }
          />

          {/* main pages */}

          {/* nested route */}

          <Route
            path="/myaccount/*"
            element={
              auth.access_token ? (
                <Layout>
                  <UserProfile />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          >
            <Route
              path="profile"
              element={
                <Layout>
                  <UserProfileRightbar />
                </Layout>
              }
            />
            <Route
              path="myrentals"
              element={
                <Layout>
                  <Myrentals />
                </Layout>
              }
            />
            <Route
              path="orders"
              element={
                <Layout>
                  <Orders />
                </Layout>
              }
            />
            <Route
              path="orderDetails/:orderId"
              element={
                <Layout>
                  <OrderDetails />
                </Layout>
              }
            />
            <Route
              path="invoiceAndPayments"
              element={
                <Layout>
                  <InvoiceAndPayments />
                </Layout>
              }
            />

            <Route
              path="wishlist"
              element={
                <Layout>
                  <Wishlist />
                </Layout>
              }
            />
            <Route
              path="ReferAndEarn"
              element={
                <Layout>
                  <ReferAndEarn />
                </Layout>
              }
            />
            <Route
              path="PaymentInvoiceSummary"
              element={
                <Layout>
                  <PaymentInvoiceSummary />
                </Layout>
              }
            />
          </Route>
          {/* nested route end*/}
          {/* protected routes */}
          <Route
            path="/ReferAndEarn"
            element={
              auth.access_token ? (
                <Layout>
                  <ReferAndEarn />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          {auth.access_token && (
            <>
              <Route
                path="/profile"
                element={
                  <Layout>
                    <UserProfileRightbar />
                  </Layout>
                }
              />
              <Route
                path="/myrentals"
                element={
                  <Layout>
                    <Myrentals />
                  </Layout>
                }
              />
              <Route
                path="/orders"
                element={
                  <Layout>
                    <Orders />
                  </Layout>
                }
              />

              <Route
                path="/orderDetails/:orderId"
                element={
                  <Layout>
                    <OrderDetails />
                  </Layout>
                }
              />
              <Route
                path="/invoiceAndPayments"
                element={
                  <Layout>
                    <InvoiceAndPayments />
                  </Layout>
                }
              />
              <Route
                path="/wishlist"
                element={
                  <Layout>
                    <Wishlist />
                  </Layout>
                }
              />
              <Route
                path="/adddeliveryaddress"
                element={
                  <Layout>
                    <AddDeliveryAddress />
                  </Layout>
                }
              />
              <Route
                path="/selectdeliveryadress"
                element={
                  <Layout>
                    <SelectDeliveryAdress />
                  </Layout>
                }
              />
            </>
          )}
          <Route
            path="invoiceAndPayments/:invoiceId"
            element={
              <Layout>
                <Protected Component={PendingInvoice} />
              </Layout>
            }
          />
          <Route
            path="/Hamburger"
            element={
              <Layout>
                <Hamburger />
              </Layout>
            }
          />
          <Route
            path="/OrderConfirmed/:confirmId"
            element={
              <Layout>
                <OrderConfirmed />
              </Layout>
            }
          />
          <Route
            path="/refer/:code"
            element={
              <Layout>
                <GoogleFbLogin />
              </Layout>
            }
          />
          {/* nested routing */}
          {auth.access_token && (
            <>
              <Route
                path="/PaymentMethods/*"
                element={
                  <Layout>
                    <PaymentMethods />
                  </Layout>
                }
              >
                <Route
                  path="UPI"
                  element={
                    <Layout>
                      <UPI />
                    </Layout>
                  }
                />
                <Route
                  path="card"
                  element={
                    <Layout>
                      <CardPayment />
                    </Layout>
                  }
                />
              </Route>
              <Route
                path="/pay"
                element={
                  <Layout>
                    <OnlinePay />
                  </Layout>
                }
              />
            </>
          )}
          <Route
            path="*"
            element={
              <Layout>
                <PageNotFound />
              </Layout>
            }
          />
          {/* routes disable for now */}
          {/* <Route path="/individual" element={<IndividualCategory />} /> */}
          {/* <Route path="/partner" element={<Partner />} /> */}
          {/* <Route path="/resetPassword" element={<ResetPassword />} /> */}
          {/* <Route path="/forgetPassword" element={<ForgetPassword />} /> */}
          {/* <Route path="/otpSent" element={<OtpSent />} /> */}
          {/* <Route path="/createNewAccount" element={<CreateNewAccount />} /> */}
          {/* <Route path="/glogin" element={<Glogin />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
