import React from "react";
import styles from "../../../assets/style/TryBeforeBuy/analytics/Table.module.css";

const ConsignmentTable = () => {
  const data = [
    {
      "Consignment No.": "XR01",
      "Received On": "1/1/2022",
      "Device Count": 40,
      "Courier Info": "RT103456",
      "Received By": "Rahul Kumar",
    },
    {
      "Consignment No.": "XR02",
      "Received On": "1/11/2022",
      "Device Count": 35,
      "Courier Info": "PR453451",
      "Received By": "Rahul Kumar",
    },
    {
      "Consignment No.": "XR03",
      "Received On": "4/11/2022",
      "Device Count": 23,
      "Courier Info": "RO103441",
      "Received By": "Rahul Kumar",
    },
    {
      "Consignment No.": "XR04",
      "Received On": "9/12/2022",
      "Device Count": 20,
      "Courier Info": "PT103421",
      "Received By": "Vijay Singh",
    },
  ];

  return (
    <div>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Consignment No.</th>
              <th>Received On</th>
              <th>Device Count</th>
              <th>Courier Info</th>
              <th>Received By</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item["Consignment No."]}</td>
                <td>{item["Received On"]}</td>
                <td>{item["Device Count"]}</td>
                <td>{item["Courier Info"]}</td>
                <td>{item["Received By"]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ConsignmentTable;
