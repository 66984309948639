const initialState = {
  couter: 0,
  itemName: "",
  category: "",
  categoryName: "",
  favItems: "",
  search: "",
};

const query = (state = initialState, action) => {
  switch (action.type) {
    case "INCRE_COUNT":
      state.couter = action.data;
      return {
        ...state,
      };
    case "SEARCH_PRODUCT":
      state.search = action.data;
      return {
        ...state,
      };
    case "SELECTED_ITEM":
      state.itemName = action.data;
      return {
        ...state,
      };
    case "SELECT_CATEGORY":
      state.category = action.data;
      return {
        ...state,
      };
    case "ADD_FAV":
      state.favItems = action.data;
      return {
        ...state,
      };
    case "SELECT_CATEGORY_NAME":
      state.categoryName = action.data;
      return {
        ...state,
      };
    case "REFER_CODE":
      return {
        ...state,
        code: action.data,
      };
    case "REMOVE_REFER_CODE":
      return {
        ...state,
        code: "",
      };
    default:
      return state;
  }
};

export default query;
